<template>
  <div class="card">
    <div class="card-body">
      <h5 class="fw-500 text-uppercase">Laporan Sales By Merchandise</h5>
      <div class="divider"></div>
      <div class="d-flex align-items-end justify-content-between">
        <div class="d-flex align-items-end">
          <div
            class="form-group me-1"
            v-show="$store.getters['laporanSales/filterOutlet']"
          >
            <label for="exampleFormControlInput30">Outlet</label>
            <input
              style="font-size: 13px"
              type="text"
              class="form-control"
              id="exampleFormControlInput30"
              v-model="search.outlet"
            />
          </div>
          <div class="form-group me-1">
            <label for="exampleFormControlInput30">Tanggal awal</label>
            <input
              style="font-size: 13px"
              type="date"
              class="form-control"
              id="exampleFormControlInput30"
              v-model="search.tglawal"
            />
          </div>
          <div class="form-group me-1">
            <label for="exampleFormControlInput30">Tanggal akhir</label>
            <input
              style="font-size: 13px"
              type="date"
              class="form-control"
              id="exampleFormControlInput30"
              v-model="search.tglakhir"
            />
          </div>
          <button
            type="button"
            class="btn btn-primary btn-sm me-1"
            @click="$store.dispatch('laporanSales/showLaporanSalesByMarchine')"
          >
            <i class="fa fa-search" aria-hidden="true"></i>
          </button>
          <button
            type="button"
            class="btn btn-secondary btn-sm me-1"
            @click="$store.dispatch('laporanSales/resetLaporan')"
          >
            <i class="fa fa-refresh" aria-hidden="true"></i>
          </button>
        </div>
        <div class="text-end">
          <button
            type="button"
            class="btn btn-secondary btn-sm me-1"
            @click="exportExcel"
          >
            <i class="fa fa-file-o icon" aria-hidden="true"></i>
            Excel
          </button>
          <button
            type="button"
            class="btn btn-secondary btn-sm me-1"
            @click="exportCsv"
          >
            <i class="fa fa-file-o icon" aria-hidden="true"></i>
            Csv
          </button>
          <button
            type="button"
            class="btn btn-secondary btn-sm me-1"
            @click="print"
          >
            <i class="fa fa-file-o icon" aria-hidden="true"></i>
            Pdf
          </button>
        </div>
      </div>
      <div class="table-responsive mt-2" style="height: 400px; font-size: 12px">
        <table class="table table-hover table-striped table-bordered">
          <thead>
            <tr class="text-center">
              <th style="width: 5%">Outlet</th>
              <th style="width: 10%">Tanggal</th>
              <th style="width: 25%" colspan="2">Nama Produk</th>
              <th style="width: 5%">Kat</th>
              <th style="width: 5%">SubKat</th>
              <th style="width: 5%">Class</th>
              <th style="width: 5%">SubClass</th>
              <th style="width: 10%">UnitPrice</th>
              <th style="width: 5%">Quantity</th>
              <th style="width: 10%">GrossSls</th>
              <th style="width: 10%">TtlDisc</th>
              <th style="width: 10%">NetSales</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(n, index) in datas" :key="index">
              <td class="text-center" :class="[n.code && n.code === 'return' ? claasReturn : '']">{{ n.Outlet }}</td>
              <td class="text-center" :class="[n.code && n.code === 'return' ? claasReturn : '']">{{ n.Date | formatDt2 }}</td>
              <td class="text-center" :class="[n.code && n.code === 'return' ? claasReturn : '']">{{ n.Internal }}</td>
              <td class="text-start" :class="[n.code && n.code === 'return' ? claasReturn : '']">{{ n.Descript }}</td>
              <td class="text-center" :class="[n.code && n.code === 'return' ? claasReturn : '']">{{ n.Catagory }}</td>
              <td class="text-center" :class="[n.code && n.code === 'return' ? claasReturn : '']">{{ n.SubCat }}</td>
              <td class="text-center" :class="[n.code && n.code === 'return' ? claasReturn : '']">{{ n.Class }}</td>
              <td class="text-center" :class="[n.code && n.code === 'return' ? claasReturn : '']">{{ n.SubClass }}</td>
              <td class="text-end" :class="[n.code && n.code === 'return' ? claasReturn : '']">{{ n.UnitPrice | Rupiah2 }}</td>
              <td class="text-center" :class="[n.code && n.code === 'return' ? claasReturn : '']">{{ n.Quantity }}</td>
              <td class="text-end" :class="[n.code && n.code === 'return' ? claasReturn : '']">{{ n.GrossSls | Rupiah2 }}</td>
              <td class="text-end" :class="[n.code && n.code === 'return' ? claasReturn : '']">{{ n.TtlDisc | Rupiah2 }}</td>
              <td class="text-end" :class="[n.code && n.code === 'return' ? claasReturn : '']">{{ n.NetSales | Rupiah2 }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="mt-2">
        <table class="table table-hover table-striped table-bordered">
          <thead>
            <tr>
              <th style="width: 30%">Total Sales</th>
              <th class="text-center" style="width: 15%">Total Quantity</th>
              <th class="text-center" style="width: 15%">Total GrossSls</th>
              <th class="text-center" style="width: 15%">Total Disc</th>
              <th class="text-center" style="width: 15%">Total Sales</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(n, index) in totalSales" :key="index">
              <td class="text-start"></td>
              <td class="text-center">{{ n.Quantity }}</td>
              <td class="text-end">{{ n.GrossSls | Rupiah2 }}</td>
              <td class="text-end">{{ n.TtlDisc | Rupiah2 }}</td>
              <td class="text-end">{{ n.NetSales | Rupiah2 }}</td>
            </tr>
          </tbody>
          <thead>
            <tr>
              <th style="width: 30%">Total Return</th>
              <th class="text-center" style="width: 15%">Total Quantity Return</th>
              <th class="text-center" style="width: 15%">Total GrossSls Return</th>
              <th class="text-center" style="width: 15%">Total Disc Return</th>
              <th class="text-center" style="width: 15%">Total Return</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(n, index) in totalReturn" :key="index">
              <td class="text-start"></td>
              <td class="text-center">{{ n.Quantity }}</td>
              <td class="text-end">{{ n.GrossSls | Rupiah2 }}</td>
              <td class="text-end">{{ n.TtlDisc | Rupiah2 }}</td>
              <td class="text-end">{{ n.NetSales | Rupiah2 }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-show="show">
        <div class="mt-2" ref="cetak">
          <div style="text-align: center">
            <h5 style="text-transform: uppercase; text-decoration: underline">
              Laporan Sales Marchine
            </h5>
            <p style="font-size: 12px; margin-top: 5px">
              Dari : {{ search.tglawal }} - Ke :
              {{ search.tglakhir }}
            </p>
          </div>

          <table class="table table-bordered" style="font-size: 12px">
            <thead>
              <tr class="text-center">
                <th style="width: 5%">Outlet</th>
                <th style="width: 10%">Tanggal</th>
                <th style="width: 25%" colspan="2">Nama Produk</th>
                <th style="width: 5%">Kat</th>
                <th style="width: 5%">SubKat</th>
                <th style="width: 5%">Class</th>
                <th style="width: 5%">SubClass</th>
                <th style="width: 10%">UnitPrice</th>
                <th style="width: 5%">Quantity</th>
                <th style="width: 10%">GrossSls</th>
                <th style="width: 10%">TtlDisc</th>
                <th style="width: 10%">NetSales</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(n, index) in datas" :key="index">
                <td class="text-center" :class="[n.code && n.code === 'return' ? claasReturn : '']">{{ n.Outlet }}</td>
                <td class="text-center" :class="[n.code && n.code === 'return' ? claasReturn : '']">{{ n.Date | formatDt2 }}</td>
                <td class="text-center" :class="[n.code && n.code === 'return' ? claasReturn : '']">{{ n.Internal }}</td>
                <td class="text-start" :class="[n.code && n.code === 'return' ? claasReturn : '']">{{ n.Descript }}</td>
                <td class="text-center" :class="[n.code && n.code === 'return' ? claasReturn : '']">{{ n.Catagory }}</td>
                <td class="text-center" :class="[n.code && n.code === 'return' ? claasReturn : '']">{{ n.SubCat }}</td>
                <td class="text-center" :class="[n.code && n.code === 'return' ? claasReturn : '']">{{ n.Class }}</td>
                <td class="text-center" :class="[n.code && n.code === 'return' ? claasReturn : '']">{{ n.SubClass }}</td>
                <td class="text-end" :class="[n.code && n.code === 'return' ? claasReturn : '']">{{ n.UnitPrice | Rupiah2 }}</td>
                <td class="text-center" :class="[n.code && n.code === 'return' ? claasReturn : '']">{{ n.Quantity }}</td>
                <td class="text-end" :class="[n.code && n.code === 'return' ? claasReturn : '']">{{ n.GrossSls | Rupiah2 }}</td>
                <td class="text-end" :class="[n.code && n.code === 'return' ? claasReturn : '']">{{ n.TtlDisc | Rupiah2 }}</td>
                <td class="text-end" :class="[n.code && n.code === 'return' ? claasReturn : '']">{{ n.NetSales | Rupiah2 }}</td>
              </tr>
            </tbody>
          </table>
          <div class="mt-2">
            <table class="table table-bordered" style="font-size: 12px">
              <thead>
                <tr>
                  <th style="width: 15%">Total Sales</th>
                  <th class="text-center" style="width: 20%">Total Quantity</th>
                  <th class="text-center" style="width: 20%">Total GrossSls</th>
                  <th class="text-center" style="width: 20%">Total Disc</th>
                  <th class="text-center" style="width: 25%">Total Sales</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(n, index) in totalSales" :key="index">
                  <td class="text-start"></td>
                  <td class="text-center">{{ n.Quantity }}</td>
                  <td class="text-end">{{ n.GrossSls | Rupiah2 }}</td>
                  <td class="text-end">{{ n.TtlDisc | Rupiah2 }}</td>
                  <td class="text-end">{{ n.NetSales | Rupiah2 }}</td>
                </tr>
              </tbody>
              <thead>
                <tr>
                  <th style="width: 15%">Total Return</th>
                  <th class="text-center" style="width: 20%">Total Quantity Return</th>
                  <th class="text-center" style="width: 20%">Total GrossSls Return</th>
                  <th class="text-center" style="width: 20%">Total Disc Return</th>
                  <th class="text-center" style="width: 25%">Total Return</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(n, index) in totalReturn" :key="index">
                  <td class="text-start"></td>
                  <td class="text-center">{{ n.Quantity }}</td>
                  <td class="text-end">{{ n.GrossSls | Rupiah2 }}</td>
                  <td class="text-end">{{ n.TtlDisc | Rupiah2 }}</td>
                  <td class="text-end">{{ n.NetSales | Rupiah2 }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Export from "@/services/Export";
import Print from "@/services/Print";
export default {
  name: "LaporanSalesByMarchineView",
  data() {
    return {
      show: false,
      claasReturn : 'bg-danger bg-gradient text-white',
    };
  },
  computed: {
    datas() {
      return this.$store.state.laporanSales.datas.sales;
    },
    totalSales() {
      return this.$store.state.laporanSales.datas.totalSales;
    },
    totalReturn() {
      return this.$store.state.laporanSales.datas.totalReturn;
    },
    search: {
      get() {
        return this.$store.state.laporanSales.search;
      },
      set(value) {
        return this.$store.commit("laporanSales/setSearch", value);
      },
    },
  },
  methods: {
    print() {
      Print.printLaporan(this.$refs.cetak);
    },
    exportCsv() {
      Export.exportCsv(this.datas, "Laporan_Transaksi_By_Marchine.csv");
    },
    exportExcel() {
      Export.exportExcels(this.datas, "Laporan_Transaksi_By_Marchine");
    },
  },
  created() {
    this.$store.dispatch("laporanSales/resetLaporan");
  },
};
</script>
